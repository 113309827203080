import React, { useState, useEffect } from "react"; // eslint-disable-line
import axios from 'axios';
// import '@zoomus/websdk/dist/css/bootstrap.css'; // Import Zoom SDK CSS
// import '@zoomus/websdk/dist/css/react-select.css'; // Import Zoom SDK CSS
import '../styles/counsellor.css';
import { useParams } from "react-router-dom";
import { api_url } from '../components/constants';
import { decrypt, encriptionKey } from '../components/utils';


const JoinSession = () => {

    const login_user = JSON.parse(localStorage.getItem('user'));
    const { meetingid } = useParams();

    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;


    useEffect(() => {

        axios.post(api_url + 'pbs/getSessionDetails', {
            meeting_id: meetingid

        }).then((res) => {

            loadZoomSDK(res);
        })

    }, []) // eslint-disable-line

    const loadZoomSDK = async (response) => {
        let class_data = response.data.data;

        const { ZoomMtg } = await import('@zoomus/websdk');
        ZoomMtg.setZoomJSLib('https://source.zoom.us/2.17.0/lib', '/av');

        ZoomMtg.preLoadWasm();
        ZoomMtg.prepareWebSDK();

        ZoomMtg.i18n.load('en-US');
        ZoomMtg.i18n.reload('en-US');

        if (class_data) {
            ZoomMtg.generateSDKSignature({
                meetingNumber: response.data.data.meeting_id,
                role: 1,
                sdkKey: class_data.sdk_key,
                sdkSecret: class_data.sdk_secret,
                success: function (signature) {
                    //console.log(signature);
                    ZoomMtg.init({
                        leaveUrl: '/counsellor-dashboard/pending-session',
                        success: (success) => {
                            ZoomMtg.join({
                                meetingNumber: class_data.meeting_id,
                                sdkKey: class_data.sdk_key,
                                signature: signature.result,
                                userName: decrypt(user_data.name, encriptionKey) + '-' + user_data.id,
                                userEmail: decrypt(user_data.email, encriptionKey),
                                // registrant_id : user_data.id,
                                passWord: 123456,

                                success: (joinSuccess) => {
                                    console.log(joinSuccess);
                                    // ZoomMtg.getSettings().disableOtherSettings(false);
                                },
                                error: (joinError) => {
                                    console.error('Error joining the meeting:', joinError);
                                },
                            });
                        },
                        error: (error) => {
                            console.log(error)
                        }
                    })
                }
            })
        } else {
            window.location.href = '/counsellor-dashboard/pending-session';
        }

    };

    return (
        ''
    )
}

export default JoinSession