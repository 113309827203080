import React, { useEffect } from "react"; // eslint-disable-line
import axios from 'axios';
// import '@zoomus/websdk/dist/css/bootstrap.css'; // Import Zoom SDK CSS
// import '@zoomus/websdk/dist/css/react-select.css'; // Import Zoom SDK CSS
import '../styles/counsellor.css';
import { useParams } from "react-router-dom";
import { api_url } from '../components/constants';
import { decrypt, encriptionKey } from '../components/utils';

const JoinClass = () => {
    const login_user = JSON.parse(localStorage.getItem('user'));
    const { meetingid } = useParams();

    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    useEffect(() => {
        axios.post(api_url + 'student/join_class', {
            meeting_id: meetingid
        }).then((res) => {
            loadZoomSDK(res);
        });
    }, []); // eslint-disable-line

    const loadZoomSDK = async (response) => {
        let class_data = response.data.data;

        const { ZoomMtg } = await import('@zoomus/websdk');
        ZoomMtg.setZoomJSLib('https://source.zoom.us/2.17.0/lib', '/av');

        ZoomMtg.preLoadWasm();
        ZoomMtg.prepareWebSDK();

        ZoomMtg.i18n.load('en-US');
        ZoomMtg.i18n.reload('en-US');
       
        if (class_data) {
            ZoomMtg.generateSDKSignature({
                meetingNumber: class_data.meeting_id,
                role: 0, // 0 for attendee, 1 for host
                sdkKey: class_data.sdk_key,
                sdkSecret: class_data.sdk_secret,
                success: function (signature) {
                    
                    ZoomMtg.init({
                        leaveUrl: '/student-dashboard/my-classes-new',
                        success: (success) => {
                            console.log(success);
                            ZoomMtg.join({
                                meetingNumber: class_data.meeting_id,
                                sdkKey: class_data.sdk_key,
                                signature: signature.result,
                                userName: decrypt(user_data.name, encriptionKey) + '-' + user_data.id,
                                userEmail: decrypt(user_data.email, encriptionKey),
                                passWord: class_data.password,
                                success: (joinSuccess) => {
                                    console.log('Join meeting success:', joinSuccess);
                                    // ZoomMtg.getSettings().disableOtherSettings(false); // Example of using settings API
                                },
                                error: (joinError) => {
                                    console.error('Error joining the meeting:', joinError);
                                },
                            });
                        },
                        error: (initError) => {
                            console.error('Error initializing Zoom SDK:', initError);
                        }
                    });
                },
                error: (signatureError) => {
                    console.error('Error generating signature:', signatureError);
                }
            });
        } else {
            window.location.href = '/student-dashboard/my-classes-new';
        }
    };

    return (
        <div>Loading...</div>
    );
};

export default JoinClass;
